import { api } from "@/api/config";

const actions = {
	async sendUpdate({ commit }, payload) {

		let temp = payload.row;

		let header = {}
		await api.post(process.env.VUE_APP_HOSTNAME + '/batch/' + payload.action, temp, header)
			.then((res) => {
				commit('upgradeProgress');
				if (res.data.code !== undefined && res.data.code >= 200 && res.data.code <= 299) {
					
					
					let row = {
						'content_id': temp.content_id,
						'course_id': temp.course_id,
						'child_id': temp.child_id,
						'action': payload.action,
						'status': 'success'
					};

					if(res.data.headers.Location) {
						const url = res.data.headers.Location;
						const taskid = url.split("/tasks/")[1];
						const newUrl = '/learn/api/public/v1/system/tasks/' + taskid;

						row.message = "Location: " + process.env.VUE_APP_BB_HOSTNAME + url + "<br>Task Status: " + process.env.VUE_APP_BB_HOSTNAME + newUrl;
					}
					
					commit('addLog', row);

				} else if (res.data.code !== undefined && res.data.code >= 400) {
					let row = {
						'content_id': temp.content_id,
						'course_id': temp.course_id,
						'child_id': temp.child_id,
						'action': payload.action,
						'status': 'fail',
						'message': res.data.message
					};
					commit('addLog', row);
				} else {
					let row = {
						'content_id': temp.content_id,
						'course_id': temp.course_id,
						'child_id': temp.child_id,
						'action': payload.action,
						'status': 'fail',
						'message': (res.data)?res.data:'Erro não esperado'
					};
					commit('addLog', row);
				}


			}).catch((error) => {
				commit('upgradeProgress');
				let row = {
					'content_id': temp.content_id,
					'course_id': temp.course_id,
					'action': payload.action,
					'status': 'fail',
					'message': error
				};
				commit('addLog', row);
			});



	},

	async startLog({ commit }) {

		await api.post(process.env.VUE_APP_HOSTNAME + '/batch/log/open')
			.then((res) => {

				commit('startLog', res.data.id);

			}).catch((error) => {
				commit('course/setFeedbackText', 'Erro ao gerar registro de Log. A operação foi cancelada.', { root: true });
				commit('course/setFeedbackType', 'danger', { root: true });
				commit('course/setFeedbackShow', true, { root: true });

				console.log(error);
			});



	},

	async endLog({ dispatch, commit, getters }) {
		commit('course/setFeedbackText', 'Operação finalizada! Gerando Log das alterações.', { root: true });
		commit('course/setFeedbackType', 'success', { root: true });
		commit('course/setFeedbackShow', true, { root: true });



		let temp = { 'log': getters.getLogRows };

		let header = {}

		api.post(process.env.VUE_APP_HOSTNAME + '/batch/log/' + getters.getLogId, temp, header)
			.then(() => {
				commit('course/setFeedbackText', 'Log gerado e disponível para consulta.', { root: true });
				commit('course/setFeedbackType', 'success', { root: true });
				commit('course/setFeedbackShow', true, { root: true });
				dispatch('loadLogs');


			}).catch((error) => {
				commit('course/setFeedbackText', 'Erro ao gerar registro de Log.', { root: true });
				commit('course/setFeedbackType', 'danger', { root: true });
				commit('course/setFeedbackShow', true, { root: true });

				console.log(error);
			});

	},

	async loadLogs({ commit }) {
		commit('setIsLoadingBatchLogs', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/batch/log/list')
			.then((res) => {

				commit('setLogList', res.data || []);

			})
			.catch((error) => {
				console.log(error);
			});
		commit('setIsLoadingBatchLogs', false);

	},
};

export default actions;
