import { api } from "@/api/config";

const actions = {

	async check_login({commit}) {
		try {
			await api.get('/login-check')
				.then((res) => {
					commit('setLogged', res.status == 204);
				})
				.catch((error) => {
					console.log(error);
					commit('setLogged', false);
				});

		} catch (error) {
			console.log(error);
			commit('setLogged', false);
		}
	},

};

export default actions;
