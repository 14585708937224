import { api } from "@/api/config";

const actions = {
	async fillCourseLinks({ commit, getters }) {

		commit('setIsLoadingChilds', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/v1/courses/' + getters.getCourseId + '/links')
			.then((resChild) => {
				commit('setCourseLinks', resChild.data || []);

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingChilds', false);

	},

	async updateTaskStatus({ commit }, payload) {

		commit('setIsLoadingChilds', true);
		await api.post(process.env.VUE_APP_HOSTNAME + '/v1/link/' + payload.link_id + '/task')
			.then((resChild) => {
				console.log(resChild);
				let newPayload = {
					index: payload.index,
					courseLink: resChild.data || {}
				}
				commit('setCourseLink', newPayload);

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingChilds', false);

	},

	async fillCourseChildInfo({ commit }, payload) {

		commit('setIsLoadingChilds', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/v1/bb/courses/' + payload.child_id + '/child')
			.then((resChild) => {
				commit('setCourseLinkInfo', {'index': payload.index, 'course': resChild.data || []});

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingChilds', false);
	},

	async fillCourseChildren({ commit, getters }) {

		commit('setIsLoadingChilds', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/v1/bb/courses/' + getters.getCourseId + '/children')
			.then((resChild) => {
				commit('setCourseChildren', resChild.data || []);

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingChilds', false);
	},
	

	async fillCourseContent({ commit, getters }) {

		commit('setIsLoadingContents', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/v1/bb/courses/' + getters.getCourseId + '/contents')
			.then((resCont) => {
				commit('setCourseContents', resCont.data || []);

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingContents', false);

	},
	async fillLogs({ commit, getters }) {

		commit('setIsLoadingLogs', true);
		await api.get(process.env.VUE_APP_HOSTNAME + '/courses/' + getters.getCourseId + '/log')
			.then((res) => {


				commit('setLogs', res.data || []);

			})
			.catch((error) => {
				console.log(error);
			});

		commit('setIsLoadingLogs', false);

	},
	async fillCourseInfo({ commit, getters }) {

		commit('setIsLoading', true);
		try {

			await api.get(process.env.VUE_APP_HOSTNAME + '/v1/bb/courses/' + getters.getCourseId, '', { 'Authorization': 'Bearer 1CPz84G8oQmZdAggFDIB2VXHlBLmnnMBbzKAmlR5' })
				.then((res) => {
					if (res.data.status != 404) {
						commit('setCourseId', res.data.id);
						commit('setCourseInfo', res.data || []);
						commit('setHasInfo', true);
					}
					else {
						commit('setHasInfo', false);
						commit('setFeedbackText', 'Disciplina não encontrada');
						commit('setFeedbackType', 'warning');
						commit('setFeedbackShow', true);
					}

				})
				.catch((error) => {
					console.log(error);
				});

			commit('setIsLoading', false);

		} catch (error) {
			console.log(error);
		}
	},
	async linkCourse({ dispatch, commit, getters }, payload) {

		commit('setIsLoadingChilds', true);

		await api.get(process.env.VUE_APP_HOSTNAME + '/v1/bb/courses/' + payload.child_id)
			.then((course) => {
				if (!course.data.id) {
					commit('setFeedbackText', 'Disciplina não encontrada');
					commit('setFeedbackType', 'warning');
					commit('setFeedbackShow', true);
					commit('setIsLoadingChilds', false);
					return;
				}

				if (course.data.id == getters.getCourseId) {
					commit('setFeedbackText', 'A disciplina não pode ser vinculada a ela mesma');
					commit('setFeedbackType', 'warning');
					commit('setFeedbackShow', true);
					commit('setIsLoadingChilds', false);
					return;
				}

				api.post(process.env.VUE_APP_HOSTNAME + '/courselinks',
					{ 'mtz_id': getters.getCourseId, 'child_id': course.data.id })
					.then(() => {

						commit('setFeedbackText', 'Disciplina vinculada com sucesso');
						commit('setFeedbackType', 'success');
						commit('setFeedbackShow', true);

						dispatch('fillCourseLinks');
						dispatch('fillLogs');
					}).catch((error) => {
						console.log(error);
						commit('setFeedbackText', 'Erro ao vincular disciplina');
						commit('setFeedbackType', 'danger');
						commit('setFeedbackShow', true);

						dispatch('fillLogs');

					});


			})
			.catch((error) => {
				console.log(error);
				commit('setFeedbackText', 'Erro ao vincular disciplina');
				commit('setFeedbackType', 'danger');
				commit('setFeedbackShow', true);

				dispatch('fillLogs');
			});

	},
	unlinkCourse: ({ dispatch, commit, getters, rootGetters }, payload) => {
		commit('setIsLoadingChilds', true);


		if (rootGetters['auth/getToken'] != null) {
			let temp = { 'ids': payload.ids };
			let header = {}

			api.post(process.env.VUE_APP_HOSTNAME + '/links/' + getters.getCourseId + '/delete', temp, header)
				.then(() => {
					commit('setFeedbackText', 'Disciplinas desvinculadas com sucesso');
					commit('setFeedbackType', 'success');
					commit('setFeedbackShow', true);

					dispatch('fillCourseLinks');
					dispatch('fillLogs');

				}).catch((error) => {
					commit('setFeedbackText', 'Erro ao desvincular disciplinas. O Log completo está disponível para download.');
					commit('setFeedbackType', 'danger');
					commit('setFeedbackShow', true);
					console.log(error);

					dispatch('fillLogs');
				});
		} else {
			commit('setFeedbackText', 'Não foi possível autenticar na API');
			commit('setFeedbackType', 'danger');
			commit('setFeedbackShow', true);

			dispatch('fillLogs');
		}


	},
	syncChilds: ({ dispatch, commit, getters }, payload) => {
		commit('setIsLoadingChilds', true);

		var temp;
		if (payload.sync_type == 'selected') {
			temp = { 'sync_type': 'selected', 'ids': payload.ids, 'create_deleted': false };
		} else {
			temp = { 'sync_type': 'all', 'create_deleted': false };
		}

		let header = {}

		api.post(process.env.VUE_APP_HOSTNAME + '/courses/' + getters.getCourseId + '/sync', temp, header)
			.then(() => {
				commit('setFeedbackText', 'Disciplinas sincronizadas com sucesso! Para detalhes acessar o Log gerado.');
				commit('setFeedbackType', 'success');
				commit('setFeedbackShow', true);

				dispatch('fillCourseLinks');
				dispatch('fillLogs');
			}).catch((error) => {
				commit('setFeedbackText', 'Erro ao sincronizar disciplinas. O Log completo está disponível para download.');
				commit('setFeedbackType', 'danger');
				commit('setFeedbackShow', true);
				dispatch('fillLogs');
				console.log(error);
			});



	},
	async changeAvailability({ commit, getters }, payload) {


		const availability = payload.availability;
		const temp = { 'availability': availability, 'ids': payload.ids };
		const header = { }

		api.post(process.env.VUE_APP_HOSTNAME + '/courses/' + getters.getCourseId + '/contents/availability', temp, header)
			.then(() => {


				commit('setFeedbackText', 'Disponibilidade alterada com sucesso! Para detalhes acesse o Log gerado.');
				commit('setFeedbackType', 'success');
				commit('setFeedbackShow', true);

				for (let index = 0; index < payload.ids.length; index++) {
					const content_id = payload.ids[index];
					commit('setAvailability', { content_id, availability });

				}


			}).catch((error) => {
				commit('setFeedbackText', 'Erro ao editar disciplinas. O Log completo está disponível para download.');
				commit('setFeedbackType', 'danger');
				commit('setFeedbackShow', true);
				console.log(error);
			});


	},



	cleanInfos: ({ commit }) => {

		commit('setCourseId', '');
		commit('setCourseInfo', []);
		commit('setCourseChildren', []);
		commit('setCourseContents', []);
		commit('setHasInfo', false);

	}

};

export default actions;
