const state = {
	courseId: '',
	courseInfo: [],
	courseContents: [],
	courseLinks: [],
	courseChildren: [],
	hasInfo: false,
	isLoading:false,
	isLoadingLogs:false,
	isLoadingChilds:false,
	isLoadingContents:false,
	feedback: {
		text: '',
		show: false,
		type: ''
	},
	log: new Array(0)
};

export default state;
